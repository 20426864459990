export const weaponsList = {
    gauntlet:{
        weaponName: "Gauntlet",
        cost: 20,
        dmgS: "1d2",
        dmgM: "1d3",
        critical: "x2",
        range: null,
        weight: 1,
        type: "B",
        cat: "simple"
    },
    dagger:{
        weaponName: "Dagger",
        cost: 20,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "19-20/x2",
        range: 10,
        weight: 1,
        type: "P or S",
        cat: "simple"
    },

    daggerPunching:{
        weaponName: "Dagger, punching",
        cost: 20,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x3",
        range: null,
        weight: 1,
        type: "P",
        cat: "simple"
    },

    gauntletSpiked:{
        weaponName: "Gauntlet, spiked",
        cost: 50,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x2",
        range: null,
        weight: 1,
        type: "P",
        cat: "simple"
    },

    maceLight:{
        weaponName: "Mace, light",
        cost: 50,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: null,
        weight: 4,
        type: "B",
        cat: "simple"
    },

    sickle:{
        weaponName: "Sickle",
        cost: 60,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: null,
        weight: 2,
        type: "S",
        cat: "simple"
    },

    club:{
        weaponName: "Club",
        cost: 0,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: 10,
        weight: 3,
        type: "B",
        cat: "simple"
    },

    maceHeavy:{
        weaponName: "Mace, heavy",
        cost: 120,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x2",
        range: null,
        weight: 8,
        type: "B",
        cat: "simple"
    },

    morningstar:{
        weaponName: "Morningstar",
        cost: 80,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x2",
        range: null,
        weight: 6,
        type: "B and P",
        cat: "simple"
    },

    shortspear:{
        weaponName: "Shortspear",
        cost: 10,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: 20,
        weight: 3,
        type: "P",
        cat: "simple"
    },

    longspear:{
        weaponName: "Longspear",
        cost: 50,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x3",
        range: null,
        weight: 9,
        type: "P",
        cat: "simple"
    },

    quarterstaff:{
        weaponName: "Quarterstaff",
        cost: 0,
        dmgS: "1d4/1d4",
        dmgM: "1d6/1d6",
        critical: "x2",
        range: null,
        weight: 4,
        type: "B",
        cat: "simple"
    },

    spear:{
        weaponName: "Spear",
        cost: 20,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x3",
        range: 20,
        weight: 6,
        type: "P",
        cat: "simple"
    },

    crossbowHeavy:{
        weaponName: "Crossbow, heavy",
        cost: 500,
        dmgS: "1d8",
        dmgM: "1d10",
        critical: "19-20/x2",
        range: 120,
        weight: 8,
        type: "P",
        cat: "simple"
    },

    crossbowLight:{
        weaponName: "Crossbow, light",
        cost: 350,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "19-20/x2",
        range: 80,
        weight: 4,
        type: "P",
        cat: "simple"
    },

    dart:{
        weaponName: "Dart",
        cost: 50,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x2",
        range: 20,
        weight: .5,
        type: "P",
        cat: "simple"
    },

    javelin:{
        weaponName: "Javelin",
        cost: 10,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: 30,
        weight: 2,
        type: "p",
        cat: "simple"
    },

    sling:{
        weaponName: "Sling",
        cost: 0,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x2",
        range: 50,
        weight: 0,
        type: "B",
        cat: "simple"
    },

    axeThrowing:{
        weaponName: "Axe, throwing",
        cost: 80,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: 10,
        weight: 2,
        type: "S",
        cat: "martial",
    },

    hammerLight:{
        weaponName: "Hammer, light",
        cost: 10,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x2",
        range: 20,
        weight: 2,
        type: "B",
        cat: "martial",
    },

    handAxe:{
        weaponName: "Handaxe",
        cost: 60,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x3",
        range: null,
        weight: 3,
        type: "S",
        cat: "martial",
    },

    kukri:{
        weaponName: "Kukri",
        cost: 80,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "18-20/x2",
        range: null,
        weight: 2,
        type: "S",
        cat: "martial",
    },

    pickLight:{
        weaponName: "Pick, light",
        cost: 40,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x4",
        range: null,
        weight: 3,
        type: "P",
        cat: "martial",
    },

    sap:{
        weaponName: "Sap",
        cost: 10,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: null,
        weight: 2,
        type: "B",
        cat: "martial",
    },

    shieldLight:{
        weaponName: "Shield, light",
        cost: null,
        dmgS: "1d2",
        dmgM: "1d3",
        critical: "x2",
        range: null,
        weight: null,
        type: "B",
        cat: "martial",
    },

    spikedArmor:{
        weaponName: "Spiked Armor",
        cost: null,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: null,
        weight: null,
        type: "P",
        cat: "martial",
    },

    spikedShieldLight:{
        weaponName: "Spiked shield, light",
        cost: null,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x2",
        range: null,
        weight: null,
        type: "B",
        cat: "martial",
    },

    swordShort:{
        weaponName: "Sword, short",
        cost: 100,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "19-20/x2",
        range: null,
        weight: 2,
        type: "P",
        cat: "martial",
    },

    battleAxe:{
        weaponName: "Battleaxe",
        cost: 100,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x3",
        range: null,
        weight: 6,
        type: "S",
        cat: "martial",
    },
    
    flail:{
        weaponName: "Flail",
        cost: 80,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x2",
        range: null,
        weight: 5,
        type: "B",
        cat: "martial",
    },

    longsword:{
        weaponName: "Longsword",
        cost: 150,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "19-20/x2",
        range: null,
        weight: 4,
        type: "S",
        cat: "martial",
    },

    pickHeavy:{
        weaponName: "Pick, heavy",
        cost: 80,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x4",
        range: null,
        weight: 6,
        type: "P",
        cat: "martial",
    },

    rapier:{
        weaponName: "Rapier",
        cost: 200,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "18-20/x2",
        range: null,
        weight: 2,
        type: "P",
        cat: "martial",
    },

    scimitar:{
        weaponName: "Scimitar",
        cost: 150,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "18-20/x2",
        range: null,
        weight: 4,
        type: "S",
        cat: "martial",
    },

    shieldHeavy:{
        weaponName: "Shield, heavy",
        cost: null,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x2",
        range: null,
        weight: null,
        type: "B",
        cat: "martial",
    },

    spikedShieldHeavy:{
        weaponName: "Spiked shield, heavy",
        cost: null,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: null,
        weight: null,
        type: "P",
        cat: "martial",
    },

    trident:{
        weaponName: "Trident",
        cost: 150,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x2",
        range: 10,
        weight: 4,
        type: "P",
        cat: "martial",
    },

    warhammer:{
        weaponName: "Warhammer",
        cost: 120,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x3",
        range: null,
        weight: 5,
        type: "B",
        cat: "martial",
    },

    falchion:{
        weaponName: "Falchion",
        cost: 750,
        dmgS: "1d6",
        dmgM: "2d4",
        critical: "18-20/x2",
        range: null,
        weight: 8,
        type: "S",
        cat: "martial",
    },

    glaive:{
        weaponName: "Glaive",
        cost: 80,
        dmgS: "1d8",
        dmgM: "1d10",
        critical: "x3",
        range: null,
        weight: 10,
        type: "S",
        cat: "martial",
    },

    greataxe:{
        weaponName: "Greataxe",
        cost: 200,
        dmgS: "1d10",
        dmgM: "1d12",
        critical: "x3",
        range: null,
        weight: 12,
        type: "S",
        cat: "martial",
    },

    greatclub:{
        weaponName: "Greatclub",
        cost: 50,
        dmgS: "1d8",
        dmgM: "1d10",
        critical: "x2",
        range: null,
        weight: 8,
        type: "B",
        cat: "martial",
    },

    flailHeavy:{
        weaponName: "Flail, heavy",
        cost: 150,
        dmgS: "1d8",
        dmgM: "1d10",
        critical: "19-20/x2",
        range: null,
        weight: 10,
        type: "B",
        cat: "martial",
    },

    greatsword:{
        weaponName: "Greatsword",
        cost: 500   ,
        dmgS: "1d10",
        dmgM: "2d6",
        critical: "19-20/x2",
        range: null,
        weight: 8,
        type: "S",
        cat: "martial",
    },

    guisarme:{
        weaponName: "Guisarme",
        cost: 90    ,
        dmgS: "1d6",
        dmgM: "2d4",
        critical: "x3",
        range: null,
        weight: 12,
        type: "S",
        cat: "martial",
    },

    halberd:{
        weaponName: "Halberd",
        cost: 100,
        dmgS: "1d8",
        dmgM: "1d10",
        critical: "x3",
        range: null,
        weight: 12,
        type: "P or S",
        cat: "martial",
    },

    lance:{
        weaponName: "Lance",
        cost: 100,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x3",
        range: null,
        weight: 10,
        type: "P",
        cat: "martial",
    },

    ranseur:{
        weaponName: "Ranseur",
        cost: 100,
        dmgS: "1d6",
        dmgM: "2d4",
        critical: "x3",
        range: null,
        weight: 12,
        type: "P",
        cat: "martial",
    },

    scythe:{
        weaponName: "Scythe",
        cost: 180,
        dmgS: "1d6",
        dmgM: "2d4",
        critical: "x4",
        range: null,
        weight: 10,
        type: "P or S",
        cat: "martial",
    },

    longbow:{
        weaponName: "Longbow",
        cost: 750,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x3",
        range: 100,
        weight: 3,
        type: "P",
        cat: "martial",
    },

    longbowComposite:{
        weaponName: "Longbow, composite",
        cost: 1000,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "x3",
        range: 110,
        weight: 3,
        type: "P",
        cat: "martial",
    },

    shortbow:{
        weaponName: "Shortbow",
        cost: 30,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x3",
        range: 60,
        weight: 2,
        type: "P",
        cat: "martial",
    },

    shortbowComposite:{
        weaponName: "Shortbow, composite",
        cost: 30,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x3",
        range: 70,
        weight: 2,
        type: "P",
        cat: "martial",
    },

    kama:{
        weaponName: "Kama",
        cost: 20,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: null,
        weight: 2,
        type: "S",
        cat: "exotic",
    },

    nunchaku:{
        weaponName: "Nunchaku",
        cost: 20,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: null,
        weight: 2,
        type: "B",
        cat: "exotic",
    },

    sai:{
        weaponName: "Sai",
        cost: 100,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x2",
        range: 10,
        weight: 1,
        type: "B",
        cat: "exotic",
    },

    siangham:{
        weaponName: "Siangham",
        cost: 30,
        dmgS: "1d4",
        dmgM: "1d6",
        critical: "x2",
        range: null,
        weight: 1,
        type: "P",
        cat: "exotic",
    },

    swordBastard:{
        weaponName: "Sword, bastard",
        cost: 350,
        dmgS: "1d8",
        dmgM: "1d10",
        critical: "19-20/x2",
        range: null,
        weight: 6,
        type: "S",
        cat: "exotic",
    },

    waraxeDwarven:{
        weaponName: "Waraxe, Dwarven",
        cost: 300,
        dmgS: "1d8",
        dmgM: "1d10",
        critical: "x3",
        range: null,
        weight: 8,
        type: "S",
        cat: "exotic",
    },

    whip:{
        weaponName: "Whip",
        cost: 10,
        dmgS: "1d2",
        dmgM: "1d3",
        critical: "x2",
        range: null,
        weight: 2,
        type: "S",
        cat: "exotic",
    },

    axeOrcDouble:{
        weaponName: "Axe, orc double",
        cost: 600,
        dmgS: "1d6/1d6",
        dmgM: "1d8/1d8",
        critical: "x3",
        range: null,
        weight: 15,
        type: "S",
        cat: "exotic",
    },

    chainSpiked:{
        weaponName: "Chain, spiked",
        cost: 250,
        dmgS: "1d6",
        dmgM: "2d4",
        critical: "x2",
        range: null,
        weight: 10,
        type: "P",
        cat: "exotic",
    },

    flailDire:{
        weaponName: "Flail, dire",
        cost: 90,
        dmgS: "1d6/1d6",
        dmgM: "1d8/1d8",
        critical: "x2",
        range: null,
        weight: 10,
        type: "B",
        cat: "exotic",
    },

    hammerGnomeHooked:{
        weaponName: "Hammer, gnome hooked",
        cost: 200,
        dmgS: "1d6/1d4",
        dmgM: "1d8/1d6",
        critical: "x3/x4",
        range: null,
        weight: 6,
        type: "B or P",
        cat: "exotic",
    },

    swordTwoBladed:{
        weaponName: "Sword, two-bladed",
        cost: 1000,
        dmgS: "1d6/1d6",
        dmgM: "1d8/1d8",
        critical: "19-20/x2",
        range: null,
        weight: 10,
        type: "S",
        cat: "exotic",
    },

    urgroshDwarven:{
        weaponName: "Urgrosh, dwarven",
        cost: 500,
        dmgS: "1d6/1d4",
        dmgM: "1d8/1d6",
        critical: "x3",
        range: null,
        weight: 12,
        type: "S or P",
        cat: "exotic",
    },

    bolas:{
        weaponName: "Bolas",
        cost: 50,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "x2",
        range: 10,
        weight: 2,
        type: "B",
        cat: "exotic",
    },

    crossbowHand:{
        weaponName: "Crossbow, hand",
        cost: 100,
        dmgS: "1d3",
        dmgM: "1d4",
        critical: "19-20/x2",
        range: 30,
        weight: 2,
        type: "P",
        cat: "exotic",
    },

    crossbowRepeatingHeavy:{
        weaponName: "Crossbow, repeating heavy",
        cost: 4000,
        dmgS: "1d8",
        dmgM: "1d10",
        critical: "19-20/x2",
        range: 120,
        weight: 12,
        type: "P",
        cat: "exotic",
    },

    crossbowRepeatingLight:{
        weaponName: "Crossbow, repeating light",
        cost: 2500,
        dmgS: "1d6",
        dmgM: "1d8",
        critical: "19-20/x2",
        range: 80,
        weight: 6,
        type: "P",
        cat: "exotic",
    },

    net:{
        weaponName: "Net",
        cost: 200,
        dmgS: null,
        dmgM: null,
        critical: null,
        range: 10,
        weight: 6,
        type: null,
        cat: "exotic",
    },

    shuriken:{
        weaponName: "Shuriken (5)",
        cost: 10,
        dmgS: "1",
        dmgM: "1d2",
        critical: "x2",
        range: 10,
        weight: .5,
        type: "P",
        cat: "exotic",
    },
    boltsCrossbow:{
        weaponName: "Bolts, crossbow (10)",
        cost: 10,
        dmgS: null,
        dmgM: null,
        critical: null,
        range: null,
        weight: 1,
        type: null,
        cat: "ammunition",
    },

    bulletsSling:{
        weaponName: "Bullets, sling (10)",
        cost: 1,
        dmgS: null,
        dmgM: null,
        critical: null,
        range: null,
        weight: 5,
        type: null,
        cat: "ammunition",
    },

    arrows:{
        weaponName: "Arrows (20)",
        cost: 10,
        dmgS: null,
        dmgM: null,
        critical: null,
        range: null,
        weight: 3,
        type: null,
        cat: "ammunition",
    },
    boltsCrossbowRepeating:{
        weaponName: "Bolts, crossbow repeating (5)",
        cost: 10,
        dmgS: null,
        dmgM: null,
        critical: null,
        range: null,
        weight: 1,
        type: null,
        cat: "ammunition",
    },
}


