export const Bard = {
  1: [0],
  2: [1],
  3: [2],
  4: [3],
  5: [3],
  6: [4],
  7: [5],
  8: [6, 1],
  9: [6, 1],
  10: [7, 2],
  11: [8, 3],
  12: [9, 4],
  13: [9, 4],
  14: [10, 5],
  15: [11, 6, 1],
  16: [12, 7, 2],
  17: [12, 7, 2],
  18: [13, 8, 3],
  19: [14, 9, 4],
  20: [15, 10, 5],
};

export const Barbarian = {
  1: [1],
  2: [2],
  3: [3],
  4: [4],
  5: [5],
  6: [6, 1],
  7: [7, 2],
  8: [8, 3],
  9: [9, 4],
  10: [10, 5],
  11: [11, 6, 1],
  12: [12, 7, 2],
  13: [13, 8, 3],
  14: [14, 9, 4],
  15: [15, 10, 5],
  16: [16, 11, 6, 1],
  17: [17, 12, 7, 2],
  18: [18, 13, 8, 3],
  19: [19, 14, 9, 4],
  20: [20, 15, 10, 5],
};

export const Cleric = {
  1: [0],
  2: [1],
  3: [2],
  4: [3],
  5: [3],
  6: [4],
  7: [5],
  8: [6, 1],
  9: [6, 1],
  10: [7, 2],
  11: [8, 3],
  12: [9, 4],
  13: [9, 4],
  14: [10, 5],
  15: [11, 6, 1],
  16: [12, 7, 2],
  17: [12, 7, 2],
  18: [13, 8, 3],
  19: [14, 9, 4],
  20: [15, 10, 5],
};

export const Druid = {
  1: [0],
  2: [1],
  3: [2],
  4: [3],
  5: [3],
  6: [4],
  7: [5],
  8: [6, 1],
  9: [6, 1],
  10: [7, 2],
  11: [8, 3],
  12: [9, 4],
  13: [9, 4],
  14: [10, 5],
  15: [11, 6, 1],
  16: [12, 7, 2],
  17: [12, 7, 2],
  18: [13, 8, 3],
  19: [14, 9, 4],
  20: [15, 10, 5],
};

export const Fighter = {
  1: [1],
  2: [2],
  3: [3],
  4: [4],
  5: [5],
  6: [6, 1],
  7: [7, 2],
  8: [8, 3],
  9: [9, 4],
  10: [10, 5],
  11: [11, 6, 1],
  12: [12, 7, 2],
  13: [13, 8, 3],
  14: [14, 9, 4],
  15: [15, 10, 5],
  16: [16, 11, 6, 1],
  17: [17, 12, 7, 2],
  18: [18, 13, 8, 3],
  19: [19, 14, 9, 4],
  20: [20, 15, 10, 5],
};

export const Monk = {
  1: [0],
  2: [1],
  3: [2],
  4: [3],
  5: [3],
  6: [4],
  7: [5],
  8: [6, 1],
  9: [6, 1],
  10: [7, 2],
  11: [8, 3],
  12: [9, 4],
  13: [9, 4],
  14: [10, 5],
  15: [11, 6, 1],
  16: [12, 7, 2],
  17: [12, 7, 2],
  18: [13, 8, 3],
  19: [14, 9, 4],
  20: [15, 10, 5],
};

export const Paladin = {
  1: [1],
  2: [2],
  3: [3],
  4: [4],
  5: [5],
  6: [6, 1],
  7: [7, 2],
  8: [8, 3],
  9: [9, 4],
  10: [10, 5],
  11: [11, 6, 1],
  12: [12, 7, 2],
  13: [13, 8, 3],
  14: [14, 9, 4],
  15: [15, 10, 5],
  16: [16, 11, 6, 1],
  17: [17, 12, 7, 2],
  18: [18, 13, 8, 3],
  19: [19, 14, 9, 4],
  20: [20, 15, 10, 5],
};

export const Ranger = {
  1: [1],
  2: [2],
  3: [3],
  4: [4],
  5: [5],
  6: [6, 1],
  7: [7, 2],
  8: [8, 3],
  9: [9, 4],
  10: [10, 5],
  11: [11, 6, 1],
  12: [12, 7, 2],
  13: [13, 8, 3],
  14: [14, 9, 4],
  15: [15, 10, 5],
  16: [16, 11, 6, 1],
  17: [17, 12, 7, 2],
  18: [18, 13, 8, 3],
  19: [19, 14, 9, 4],
  20: [20, 15, 10, 5],
};

export const Rogue = {
  1: [0],
  2: [1],
  3: [2],
  4: [3],
  5: [3],
  6: [4],
  7: [5],
  8: [6, 1],
  9: [6, 1],
  10: [7, 2],
  11: [8, 3],
  12: [9, 4],
  13: [9, 4],
  14: [10, 5],
  15: [11, 6, 1],
  16: [12, 7, 2],
  17: [12, 7, 2],
  18: [13, 8, 3],
  19: [14, 9, 4],
  20: [15, 10, 5],
};

export const Sorcerer = {
  1: [0],
  2: [1],
  3: [1],
  4: [2],
  5: [2],
  6: [3],
  7: [3],
  8: [4],
  9: [4],
  10: [5],
  11: [5],
  12: [6, 1],
  13: [6, 1],
  14: [7, 2],
  15: [7, 2],
  16: [8, 3],
  17: [8, 3],
  18: [9, 4],
  19: [9, 4],
  20: [10, 5],
};

export const Wizard = {
  1: [0],
  2: [1],
  3: [1],
  4: [2],
  5: [2],
  6: [3],
  7: [3],
  8: [4],
  9: [4],
  10: [5],
  11: [5],
  12: [6, 1],
  13: [6, 1],
  14: [7, 2],
  15: [7, 2],
  16: [8, 3],
  17: [8, 3],
  18: [9, 4],
  19: [9, 4],
  20: [10, 5],
};
