
export const skillTables = {
appraise: {
    skillName: "Appraise",
    ability: "Int",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
balance: {
    skillName: "Balance",
    ability: "Dex",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: true,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
bluff: {
    skillName: "Bluff",
    ability: "Cha",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: true,
    Wizard: false,
},
climb: {
    skillName: "Climb",
    ability: "Str",
    untrained: true,
    Barbarian: true,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: true,
    Monk: true,
    Paladin: false,
    Ranger: true,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
concentraion: {
    skillName: "Concentration",
    ability: "Con",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: true,
    Druid: true,
    Fighter: false,
    Monk: true,
    Paladin: true,
    Ranger: true,
    Rogue: false,
    Sorcerer: true,
    Wizard: true,
},
craft: {
    skillName: "Craft",
    ability: "Int",
    untrained: true,
    Barbarian: true,
    Bard: true,
    Cleric: true,
    Druid: true,
    Fighter: true,
    Monk: true,
    Paladin: true,
    Ranger: true,
    Rogue: true,
    Sorcerer: true,
    Wizard: true,
},
decipher: {
    skillName: "Decipher Script",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: true,
},
diplomacy: {
    skillName: "Diplomacy",
    ability: "Cha",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: true,
    Druid: true,
    Fighter: false,
    Monk: true,
    Paladin: true,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
disable: {
    skillName: "Disable Device",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: false,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
disguise: {
    skillName: "Disguise",
    ability: "Cha",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
escapeArtist: {
    skillName: "Escape Artist",
    ability: "Dex",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: true,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
forgery: {
    skillName: "Forgery",
    ability: "Int",
    untrained: true,
    Barbarian: false,
    Bard: false,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
gatherInformation: {
    skillName: "Gather Information",
    ability: "Cha",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
handleAnimal: {
    skillName: "Handle Animal",
    ability: "Cha",
    untrained: false,
    Barbarian: true,
    Bard: false,
    Cleric: false,
    Druid: true,
    Fighter: true,
    Monk: false,
    Paladin: true,
    Ranger: true,
    Rogue: false,
    Sorcerer: false,
    Wizard: false,
},
heal: {
    skillName: "Heal",
    ability: "Wis",
    untrained: true,
    Barbarian: false,
    Bard: false,
    Cleric: true,
    Druid: true,
    Fighter: false,
    Monk: false,
    Paladin: true,
    Ranger: true,
    Rogue: false,
    Sorcerer: false,
    Wizard: false,
},
hide: {
    skillName: "Hide",
    ability: "Dex",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: true,
    Paladin: false,
    Ranger: true,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
intimidate: {
    skillName: "Intimidate",
    ability: "Cha",
    untrained: true,
    Barbarian: true,
    Bard: false,
    Cleric: false,
    Druid: false,
    Fighter: true,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
jump: {
    skillName: "Jump",
    ability: "Str",
    untrained: true,
    Barbarian: true,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: true,
    Monk: true,
    Paladin: false,
    Ranger: true,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
knowledgeArcana: {
    skillName: "Knowledge (arcana)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: true,
    Druid: false,
    Fighter: false,
    Monk: true,
    Paladin: false,
    Ranger: false,
    Rogue: false,
    Sorcerer: true,
    Wizard: true,
},
knowledgeArchitect: {
    skillName: "Knowledge (architecture and engineering)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: false,
    Sorcerer: false,
    Wizard: true,
},

knowledgeDungeon: {
    skillName: "Knowledge (dungeoneering)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: true,
    Rogue: false,
    Sorcerer: false,
    Wizard: true,
},

knowledgeGeography: {
    skillName: "Knowledge (geography)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: true,
    Rogue: false,
    Sorcerer: false,
    Wizard: true,
},

knowledgeHistory: {
    skillName: "Knowledge (history)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: true,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: false,
    Sorcerer: false,
    Wizard: true,
},

knowledgelocal: {
    skillName: "Knowledge (local)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: true,
    Rogue: false,
    Sorcerer: false,
    Wizard: true,
},

knowledgeNature: {
    skillName: "Knowledge (nature)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: true,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: true,
    Rogue: false,
    Sorcerer: false,
    Wizard: true,
},

knowledgeNobility: {
    skillName: "Knowledge (nobility and royalty)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: true,
    Ranger: false,
    Rogue: false,
    Sorcerer: false,
    Wizard: true,
},

knowledgeReligion: {
    skillName: "Knowledge (religion)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: true,
    Druid: false,
    Fighter: false,
    Monk: true,
    Paladin: true,
    Ranger: false,
    Rogue: false,
    Sorcerer: false,
    Wizard: true,
},

knowledgePlanes: {
    skillName: "Knowledge (the planes)",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: true,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: false,
    Sorcerer: false,
    Wizard: true,
},

listen: {
    skillName: "Listen",
    ability: "Wis",
    untrained: true,
    Barbarian: true,
    Bard: true,
    Cleric: false,
    Druid: true,
    Fighter: false,
    Monk: true,
    Paladin: false,
    Ranger: true,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

moveSilently: {
    skillName: "Move Silently",
    ability: "Dex",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: true,
    Paladin: false,
    Ranger: true,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

openLock: {
    skillName: "Open Lock",
    ability: "Dex",
    untrained: false,
    Barbarian: false,
    Bard: false,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

perform: {
    skillName: "Perform",
    ability: "Dex",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: true,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

profession: {
    skillName: "Profession",
    ability: "Wis",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: true,
    Druid: true,
    Fighter: false,
    Monk: true,
    Paladin: true,
    Ranger: true,
    Rogue: true,
    Sorcerer: true,
    Wizard: true,
},

ride: {
    skillName: "Ride",
    ability: "Dex",
    untrained: true,
    Barbarian: true,
    Bard: false,
    Cleric: false,
    Druid: true,
    Fighter: true,
    Monk: false,
    Paladin: true,
    Ranger: true,
    Rogue: false,
    Sorcerer: false,
    Wizard: false,
},

search: {
    skillName: "Search",
    ability: "Int",
    untrained: true,
    Barbarian: false,
    Bard: false,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: true,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

senseMotive: {
    skillName: "Sense Motive",
    ability: "Int",
    untrained: true,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: true,
    Paladin: true,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

sleightHand: {
    skillName: "Sleight of Hand",
    ability: "Dex",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

speakLanguage: {
    skillName: "Speak Language",
    ability: null,
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: false,
    Sorcerer: false,
    Wizard: false,
},

spellcraft: {
    skillName: "Spellcraft",
    ability: "Int",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: true,
    Druid: true,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: false,
    Sorcerer: true,
    Wizard: true,
},

spot: {
    skillName: "Spot",
    ability: "Wis",
    untrained: true,
    Barbarian: false,
    Bard: false,
    Cleric: false,
    Druid: true,
    Fighter: false,
    Monk: true,
    Paladin: false,
    Ranger: true,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

survival: {
    skillName: "Survival",
    ability: "Wis",
    untrained: true,
    Barbarian: true,
    Bard: false,
    Cleric: false,
    Druid: true,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: true,
    Rogue: false,
    Sorcerer: false,
    Wizard: false,
},

swim: {
    skillName: "Swim",
    ability: "Str",
    untrained: true,
    Barbarian: true,
    Bard: true,
    Cleric: false,
    Druid: true,
    Fighter: true,
    Monk: true,
    Paladin: false,
    Ranger: true,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

tumble: {
    skillName: "Tumble",
    ability: "Dex",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: true,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

useMagicDevice: {
    skillName: "Use Magic Device",
    ability: "Cha",
    untrained: false,
    Barbarian: false,
    Bard: true,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: false,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},

useRope: {
    skillName: "Use Rope",
    ability: "Dex",
    untrained: true,
    Barbarian: false,
    Bard: false,
    Cleric: false,
    Druid: false,
    Fighter: false,
    Monk: false,
    Paladin: false,
    Ranger: true,
    Rogue: true,
    Sorcerer: false,
    Wizard: false,
},
}
