export const Barbarian = {
    1: {
        f: 2,
        r: 0,
        w: 0,
    },
    2: {
        f: 3,
        r: 0,
        w: 0,
    },
    3: {
        f: 3,
        r: 1,
        w: 1,
    },
    4: {
        f: 4,
        r: 1,
        w: 1,
    },
    5: {
        f: 4,
        r: 1,
        w: 1,
    },
    6: {
        f: 5,
        r: 2,
        w: 2,
    },
    7: {
        f: 5,
        r: 2,
        w: 2,
    },
    8: {
        f: 6,
        r: 2,
        w: 2,
    },
    9: {
        f: 6,
        r: 3,
        w: 3,
    },
    10: {
        f: 7,
        r: 3,
        w: 3,
    },
    11: {
        f: 7,
        r: 3,
        w: 3,
    },
    12: {
        f: 8,
        r: 4,
        w: 4,
    },
    13: {
        f: 8,
        r: 4,
        w: 4,
    },
    14: {
        f: 9,
        r: 4,
        w: 4,
    },
    15: {
        f: 9,
        r: 5,
        w: 5,
    },
    16: {
        f: 10,
        r: 5,
        w: 5,
    },
    17: {
        f: 10,
        r: 5,
        w: 5,
    },
    18: {
        f: 11,
        r: 6,
        w: 6,
    },
    19: {
        f: 11,
        r: 6,
        w: 6,
    },
    20: {
        f: 12,
        r: 6,
        w: 6,
    },
};

export const Bard = {
    1: {
        f: 0,
        r: 2,
        w: 2,
    },
    2: {
        f: 0,
        r: 3,
        w: 3,
    },
    3: {
        f: 1,
        r: 3,
        w: 3,
    },
    4: {
        f: 1,
        r: 4,
        w: 4,
    },
    5: {
        f: 1,
        r: 4,
        w: 4,
    },
    6: {
        f: 2,
        r: 5,
        w: 5,
    },
    7: {
        f: 2,
        r: 5,
        w: 5,
    },
    8: {
        f: 2,
        r: 6,
        w: 6,
    },
    9: {
        f: 3,
        r: 6,
        w: 6,
    },
    10: {
        f: 3,
        r: 7,
        w: 7,
    },
    11: {
        f: 3,
        r: 7,
        w: 7,
    },
    12: {
        f: 4,
        r: 8,
        w: 8,
    },
    13: {
        f: 4,
        r: 8,
        w: 8,
    },
    14: {
        f: 4,
        r: 9,
        w: 9,
    },
    15: {
        f: 5,
        r: 9,
        w: 9,
    },
    16: {
        f: 5,
        r: 10,
        w: 10,
    },
    17: {
        f: 5,
        r: 10,
        w: 10,
    },
    18: {
        f: 6,
        r: 11,
        w: 11,
    },
    19: {
        f: 6,
        r: 11,
        w: 11,
    },
    20: {
        f: 6,
        r: 12,
        w: 12,
    },
}

export const Cleric = {
    1: {
        f: 2,
        r: 0,
        w: 2,
    },
    2: {
        f: 3,
        r: 0,
        w: 3,
    },
    3: {
        f: 3,
        r: 1,
        w: 3,
    },
    4: {
        f: 4,
        r: 1,
        w: 4,
    },
    5: {
        f: 4,
        r: 1,
        w: 4,
    },
    6: {
        f: 5,
        r: 2,
        w: 5,
    },
    7: {
        f: 5,
        r: 2,
        w: 5,
    },
    8: {
        f: 6,
        r: 2,
        w: 6,
    },
    9: {
        f: 6,
        r: 3,
        w: 6,
    },
    10: {
        f: 7,
        r: 3,
        w: 7,
    },
    11: {
        f: 7,
        r: 3,
        w: 7,
    },
    12: {
        f: 8,
        r: 4,
        w: 8,
    },
    13: {
        f: 8,
        r: 4,
        w: 8,
    },
    14: {
        f: 9,
        r: 4,
        w: 9,
    },
    15: {
        f: 9,
        r: 5,
        w: 9,
    },
    16: {
        f: 10,
        r: 5,
        w: 10,
    },
    17: {
        f: 10,
        r: 5,
        w: 10,
    },
    18: {
        f: 11,
        r: 6,
        w: 11,
    },
    19: {
        f: 11,
        r: 6,
        w: 11,
    },
    20: {
        f: 12,
        r: 6,
        w: 12,
    },
};

export const Druid = {
    1: {
        f: 2,
        r: 0,
        w: 2,
    },
    2: {
        f: 3,
        r: 0,
        w: 3,
    },
    3: {
        f: 3,
        r: 1,
        w: 3,
    },
    4: {
        f: 4,
        r: 1,
        w: 4,
    },
    5: {
        f: 4,
        r: 1,
        w: 4,
    },
    6: {
        f: 5,
        r: 2,
        w: 5,
    },
    7: {
        f: 5,
        r: 2,
        w: 5,
    },
    8: {
        f: 6,
        r: 2,
        w: 6,
    },
    9: {
        f: 6,
        r: 3,
        w: 6,
    },
    10: {
        f: 7,
        r: 3,
        w: 7,
    },
    11: {
        f: 7,
        r: 3,
        w: 7,
    },
    12: {
        f: 8,
        r: 4,
        w: 8,
    },
    13: {
        f: 8,
        r: 4,
        w: 8,
    },
    14: {
        f: 9,
        r: 4,
        w: 9,
    },
    15: {
        f: 9,
        r: 5,
        w: 9,
    },
    16: {
        f: 10,
        r: 5,
        w: 10,
    },
    17: {
        f: 10,
        r: 5,
        w: 10,
    },
    18: {
        f: 11,
        r: 6,
        w: 11,
    },
    19: {
        f: 11,
        r: 6,
        w: 11,
    },
    20: {
        f: 12,
        r: 6,
        w: 12,
    },
}

export const Fighter = {
    1: {
        f: 2,
        r: 0,
        w: 0,
    },
    2: {
        f: 3,
        r: 0,
        w: 0,
    },
    3: {
        f: 3,
        r: 1,
        w: 1,
    },
    4: {
        f: 4,
        r: 1,
        w: 1,
    },
    5: {
        f: 4,
        r: 1,
        w: 1,
    },
    6: {
        f: 5,
        r: 2,
        w: 2,
    },
    7: {
        f: 5,
        r: 2,
        w: 2,
    },
    8: {
        f: 6,
        r: 2,
        w: 2,
    },
    9: {
        f: 6,
        r: 3,
        w: 3,
    },
    10: {
        f: 7,
        r: 3,
        w: 3,
    },
    11: {
        f: 7,
        r: 3,
        w: 3,
    },
    12: {
        f: 8,
        r: 4,
        w: 4,
    },
    13: {
        f: 8,
        r: 4,
        w: 4,
    },
    14: {
        f: 9,
        r: 4,
        w: 4,
    },
    15: {
        f: 9,
        r: 5,
        w: 5,
    },
    16: {
        f: 10,
        r: 5,
        w: 5,
    },
    17: {
        f: 10,
        r: 5,
        w: 5,
    },
    18: {
        f: 11,
        r: 6,
        w: 6,
    },
    19: {
        f: 11,
        r: 6,
        w: 6,
    },
    20: {
        f: 12,
        r: 6,
        w: 6,
    },

};

export const Monk = {
    1: {
        f: 2,
        r: 2,
        w: 2,
    },
    2: {
        f: 3,
        r: 3,
        w: 3,
    },
    3: {
        f: 3,
        r: 3,
        w: 3,
    },
    4: {
        f: 4,
        r: 4,
        w: 4,
    },
    5: {
        f: 4,
        r: 4,
        w: 4,
    },
    6: {
        f: 5,
        r: 5,
        w: 5,
    },
    7: {
        f: 5,
        r: 5,
        w: 5,
    },
    8: {
        f: 6,
        r: 6,
        w: 6,
    },
    9: {
        f: 6,
        r: 6,
        w: 6,
    },
    10: {
        f: 7,
        r: 7,
        w: 7,
    },
    11: {
        f: 7,
        r: 7,
        w: 7,
    },
    12: {
        f: 8,
        r: 8,
        w: 8,
    },
    13: {
        f: 8,
        r: 8,
        w: 8,
    },
    14: {
        f: 9,
        r: 9,
        w: 9,
    },
    15: {
        f: 9,
        r: 9,
        w: 9,
    },
    16: {
        f: 10,
        r: 10,
        w: 10,
    },
    17: {
        f: 10,
        r: 10,
        w: 10,
    },
    18: {
        f: 11,
        r: 11,
        w: 11,
    },
    19: {
        f: 11,
        r: 11,
        w: 11,
    },
    20: {
        f: 12,
        r: 12,
        w: 12,
    },
};

export const Paladin = {
    1: {
        f: 2,
        r: 0,
        w: 0,
    },
    2: {
        f: 3,
        r: 0,
        w: 0,
    },
    3: {
        f: 3,
        r: 1,
        w: 1,
    },
    4: {
        f: 4,
        r: 1,
        w: 1,
    },
    5: {
        f: 4,
        r: 1,
        w: 1,
    },
    6: {
        f: 5,
        r: 2,
        w: 2,
    },
    7: {
        f: 5,
        r: 2,
        w: 2,
    },
    8: {
        f: 6,
        r: 2,
        w: 2,
    },
    9: {
        f: 6,
        r: 3,
        w: 3,
    },
    10: {
        f: 7,
        r: 3,
        w: 3,
    },
    11: {
        f: 7,
        r: 3,
        w: 3,
    },
    12: {
        f: 8,
        r: 4,
        w: 4,
    },
    13: {
        f: 8,
        r: 4,
        w: 4,
    },
    14: {
        f: 9,
        r: 4,
        w: 4,
    },
    15: {
        f: 9,
        r: 5,
        w: 5,
    },
    16: {
        f: 10,
        r: 5,
        w: 5,
    },
    17: {
        f: 10,
        r: 5,
        w: 5,
    },
    18: {
        f: 11,
        r: 6,
        w: 6,
    },
    19: {
        f: 11,
        r: 6,
        w: 6,
    },
    20: {
        f: 12,
        r: 6,
        w: 6,
    },
}

export const Ranger = {
    1: {
        f: 2,
        r: 2,
        w: 0,
    },
    2: {
        f: 3,
        r: 3,
        w: 0,
    },
    3: {
        f: 3,
        r: 3,
        w: 1,
    },
    4: {
        f: 4,
        r: 4,
        w: 1,
    },
    5: {
        f: 4,
        r: 4,
        w: 1,
    },
    6: {
        f: 5,
        r: 5,
        w: 2,
    },
    7: {
        f: 5,
        r: 5,
        w: 2,
    },
    8: {
        f: 6,
        r: 6,
        w: 2,
    },
    9: {
        f: 6,
        r: 6,
        w: 3,
    },
    10: {
        f: 7,
        r: 7,
        w: 3,
    },
    11: {
        f: 7,
        r: 7,
        w: 3,
    },
    12: {
        f: 8,
        r: 8,
        w: 4,
    },
    13: {
        f: 8,
        r: 8,
        w: 4,
    },
    14: {
        f: 9,
        r: 9,
        w: 4,
    },
    15: {
        f: 9,
        r: 9,
        w: 5,
    },
    16: {
        f: 10,
        r: 10,
        w: 5,
    },
    17: {
        f: 10,
        r: 10,
        w: 5,
    },
    18: {
        f: 11,
        r: 11,
        w: 6,
    },
    19: {
        f: 11,
        r: 11,
        w: 6,
    },
    20: {
        f: 12,
        r: 12,
        w: 6,
    },
};

export const Rogue = {
    1: {
        f: 0,
        r: 2,
        w: 0,
    },
    2: {
        f: 0,
        r: 3,
        w: 0,
    },
    3: {
        f: 1,
        r: 3,
        w: 1,
    },
    4: {
        f: 1,
        r: 4,
        w: 1,
    },
    5: {
        f: 1,
        r: 4,
        w: 1,
    },
    6: {
        f: 2,
        r: 5,
        w: 2,
    },
    7: {
        f: 2,
        r: 5,
        w: 2,
    },
    8: {
        f: 2,
        r: 6,
        w: 2,
    },
    9: {
        f: 3,
        r: 6,
        w: 3,
    },
    10: {
        f: 3,
        r: 7,
        w: 3,
    },
    11: {
        f: 3,
        r: 7,
        w: 3,
    },
    12: {
        f: 4,
        r: 8,
        w: 4,
    },
    13: {
        f: 4,
        r: 8,
        w: 4,
    },
    14: {
        f: 4,
        r: 9,
        w: 4,
    },
    15: {
        f: 5,
        r: 9,
        w: 5,
    },
    16: {
        f: 5,
        r: 10,
        w: 5,
    },
    17: {
        f: 5,
        r: 10,
        w: 5,
    },
    18: {
        f: 6,
        r: 11,
        w: 6,
    },
    19: {
        f: 6,
        r: 11,
        w: 6,
    },
    20: {
        f: 6,
        r: 12,
        w: 6,
    },
};

export const Sorcerer = {
    1: {
        f: 0,
        r: 0,
        w: 2,
    },
    2: {
        f: 0,
        r: 0,
        w: 3,
    },
    3: {
        f: 1,
        r: 1,
        w: 3,
    },
    4: {
        f: 1,
        r: 1,
        w: 4,
    },
    5: {
        f: 1,
        r: 1,
        w: 4,
    },
    6: {
        f: 2,
        r: 2,
        w: 5,
    },
    7: {
        f: 2,
        r: 2,
        w: 5,
    },
    8: {
        f: 2,
        r: 2,
        w: 6,
    },
    9: {
        f: 3,
        r: 3,
        w: 6,
    },
    10: {
        f: 3,
        r: 3,
        w: 7,
    },
    11: {
        f: 3,
        r: 3,
        w: 7,
    },
    12: {
        f: 4,
        r: 4,
        w: 8,
    },
    13: {
        f: 4,
        r: 4,
        w: 8,
    },
    14: {
        f: 4,
        r: 4,
        w: 9,
    },
    15: {
        f: 5,
        r: 5,
        w: 9,
    },
    16: {
        f: 5,
        r: 5,
        w: 10,
    },
    17: {
        f: 5,
        r: 5,
        w: 10,
    },
    18: {
        f: 6,
        r: 6,
        w: 11,
    },
    19: {
        f: 6,
        r: 6,
        w: 11,
    },
    20: {
        f: 6,
        r: 6,
        w: 12,
    },
};

export const Wizard = {
    1: {
        f: 0,
        r: 0,
        w: 2,
    },
    2: {
        f: 0,
        r: 0,
        w: 3,
    },
    3: {
        f: 1,
        r: 1,
        w: 3,
    },
    4: {
        f: 1,
        r: 1,
        w: 4,
    },
    5: {
        f: 1,
        r: 1,
        w: 4,
    },
    6: {
        f: 2,
        r: 2,
        w: 5,
    },
    7: {
        f: 2,
        r: 2,
        w: 5,
    },
    8: {
        f: 2,
        r: 2,
        w: 6,
    },
    9: {
        f: 3,
        r: 3,
        w: 6,
    },
    10: {
        f: 3,
        r: 3,
        w: 7,
    },
    11: {
        f: 3,
        r: 3,
        w: 7,
    },
    12: {
        f: 4,
        r: 4,
        w: 8,
    },
    13: {
        f: 4,
        r: 4,
        w: 8,
    },
    14: {
        f: 4,
        r: 4,
        w: 9,
    },
    15: {
        f: 5,
        r: 5,
        w: 9,
    },
    16: {
        f: 5,
        r: 5,
        w: 10,
    },
    17: {
        f: 5,
        r: 5,
        w: 10,
    },
    18: {
        f: 6,
        r: 6,
        w: 11,
    },
    19: {
        f: 6,
        r: 6,
        w: 11,
    },
    20: {
        f: 6,
        r: 6,
        w: 12,
    },
};