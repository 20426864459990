export const hitDice = {
    Sorcerer: 4,
    Wizard: 4,
    Bard: 6,
    Rogue: 6,
    Cleric: 8,
    Druid: 8,
    Monk: 8,
    Ranger: 8,
    Fighter: 10,
    Paladin: 10,
    Barbarian: 12,
  };

  